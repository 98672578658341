/** @format */
import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { storageFor } from 'ember-local-storage';
import { tracked } from '@glimmer/tracking';

export default class AppFirmController extends Controller {
	@service company;
	@service currentUser;
	@service mobile;
	@service permissions;
	@service router;
	@service session;
	@service thirdParty;
	@service chromeExtension;
	@service('saas-customer-support') saasCustomerSupport;

	@tracked showAccountMenu = false;
	@tracked showInboxModal = false;

	preferences = storageFor('preferences');

	get isInboxActive() {
		return this.router.currentRouteName === 'app.firm.inbox';
	}

	get inboxNotifications() {
		const notifications = this.model.notifications
			? this.model.notifications.toArray()
			: [];
		const chatNotifications = this.model.chatNotifications
			? this.model.chatNotifications.toArray()
			: [];
		return A([...notifications, ...chatNotifications]);
	}

	get notificationsSorted() {
		return this.inboxNotifications.sortBy('createdAt').reverse();
	}

	get notificationsForModal() {
		return this.notificationsSorted
			? this.notificationsSorted.slice(0, 10)
			: [];
	}

	get unreadCount() {
		let count = 0;
		if (this.model.notifications) {
			count += this.model.notifications.filterBy('readAt', null).length;
		}
		if (this.model.chatNotifications) {
			count += this.model.chatNotifications.filterBy('readAt', null).length;
		}
		setTimeout(() => {
			// wait a half second to avoid a race condition when opening the app
			this.chromeExtension.sendUnreadCount(count);
		}, 500);

		return count;
	}

	@action logout() {
		this.currentUser.logout();
	}

	@action toggleInboxModal() {
		this.showInboxModal = !this.showInboxModal;
	}

	@action toggleShowAccountMenu() {
		this.showAccountMenu = !this.showAccountMenu;
	}
}
