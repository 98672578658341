/** @format */

import { A } from '@ember/array';
import { sort } from '@ember/object/computed';
import { computed, observer } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default Controller.extend({
	thirdParty: service(),
	preferences: storageFor('preferences'),
	showAccountMenu: false,
	isInboxActive: computed('routing.currentRouteName', function () {
		return this.get('routing.currentRouteName') === 'app.organization.inbox';
	}),
	inboxNotifications: computed('model.notifications.[]', function () {
		const chatNotifications = this.get('model.chatNotifications')
			? this.get('model.chatNotifications').toArray()
			: [];

		return A([...chatNotifications]);
	}),
	notificationsSort: ['createdAt:desc'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	notificationsSorted: sort('inboxNotifications', 'notificationsSort'),
	notificationsForModal: computed('notificationsSorted.[]', function () {
		if (this.notificationsSorted) {
			return this.notificationsSorted.slice(0, 10);
		}
	}),
	routing: service('-routing'),
	currentUser: service('current-user'),
	session: service('session'),
	saasCustomerSupport: service('saas-customer-support'),
	showInboxModal: false,

	unreadCount: computed(
		'model.{notifications,chatNotifications}.@each.readAt',
		function () {
			let count = 0;

			if (this.model.chatNotifications) {
				count += this.model.chatNotifications.filterBy('readAt', null).length;
			}

			return count;
		},
	),

	actions: {
		logout() {
			this.currentUser.logout();
		},

		toggleInboxModal() {
			this.toggleProperty('showInboxModal');
		},

		toggleShowAccountMenu() {
			this.toggleProperty('showAccountMenu');
		},
	},
});
