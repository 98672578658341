/** @format */

import { defer } from 'rsvp';
import { A } from '@ember/array';
import { sort, filter } from '@ember/object/computed';
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { attorneyDisplay } from 'case-status/helpers/attorney-display';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import moment from 'moment';

@classic
export default class CaseInfoModal extends Component {
	@tracked showDateError = false;
	@tracked showErrorAttorneys = true;
	@tracked dateValidationError = '';
	@service currentUser;
	@service company;
	@service permissions;

	@tracked date;
	@tracked isPortalCustomer = this.currentUser.isPortalCustomer;

	@tracked attorneys;
	@tracked paralegals;

	dateObserver() {
		const firm = this.currentUser.get('user.firm');
		const dateFormat = firm.get('isEu') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

		const dateInput = this.element?.querySelector('#Date');
		const dateValidationError = firm.get('isEu')
			? 'Date must be in DD/MM/YYYY format'
			: 'Date must be in MM/DD/YYYY format';
		const momentDate = moment(this.date, dateFormat);

		// Dates are complicated. This checks to see if there are any blank spaces in the date input and if there are
		// we will ensure that users can not input an invalid date.
		if (
			(this.date &&
				momentDate?.isValid() &&
				!dateInput?.value?.split('')?.includes('_')) ||
			!dateInput?.value
		) {
			this.set('showDateError', false);
			this.set('dateValidationError', '');
			this.set('model.date', momentDate.format('YYYY-MM-DD'));
		} else {
			this.set('dateValidationError', dateValidationError);
			this.set('showDateError', true);
			this.set('model.date', null);
		}
	}

	attorneyObserver() {
		if (!this.model) return null;
		const attorneysLength = this.model.get('attorneys.length');
		this.set('showErrorAttorneys', attorneysLength < 1);
	}

	caseTypeObserver() {
		this.set('model.caseStatus', '');
	}

	@filter('attorneys.@each.emailAddress', function (attorney) {
		const emailAddress = attorney.get('emailAddress') || '';

		return (
			!emailAddress.includes('@casestatus.com') &&
			attorney.get('firm.id') === this.company.get('info.id')
		);
	})
	filteredAttorneys;

	@filter('paralegals.@each.emailAddress', function (paralegal) {
		const emailAddress = paralegal.get('emailAddress') || '';
		return (
			!emailAddress.includes('@casestatus.com') &&
			paralegal.get('firm.id') === this.company.get('info.id')
		);
	})
	filteredParalegals;

	get potentialStatuses() {
		//* Should return an array, regardless of existing response or not
		const defaultResponse = [];

		//* If no model yet, return default response
		if (!this.model) return defaultResponse;

		//* Get the caseType from the existing model
		const caseType = this.model.get('caseType');

		//* return the related statues
		if (caseType) return this.model.get('caseType.caseStatuses');

		//* Fallback response
		return defaultResponse;
	}

	get caseActivationQuota() {
		return this.permissions?.firmSettings?.case_activation_quota;
	}

	get caseActivationCount() {
		return this.permissions?.firmSettings?.case_activation_count;
	}

	get caseActivationQuotaExceeded() {
		return this.permissions?.firmSettings?.case_activation_quota_exceeded;
	}

	sortedCaseStatusesObserver() {
		if (this.sortedCaseStatuses?.length > 0) {
			if (!this.model) return null;

			//* set the first status available once it becomes available
			const firstStatus = this.sortedCaseStatuses.get('firstObject');
			this.set('model.caseStatus', firstStatus);
		}
	}

	@sort('potentialStatuses', 'sortDefinitionCaseStatus')
	sortedCaseStatuses;

	@sort('filteredAttorneys', 'sortDefinition')
	sortedAttorneys;

	@sort('filteredParalegals', 'sortDefinition')
	sortedParalegals;

	@sort('caseTypes', 'sortDefinitionCaseType')
	sortedCaseTypes;

	sortDefinition = ['lastName'];
	sortDefinitionCaseType = ['name'];
	sortDefinitionCaseStatus = ['number'];
	noAttorneysError = '';

	@service() router;

	async init() {
		super.init(...arguments);

		//! Will make a request to API every time they go to create a new case
		//! Can't do the usual peekAll and fallback to findAll when empty since some caseType come with the cases request...
		const attorneys = await this.store.findAll('attorney');
		this.set('attorneys', attorneys.toArray());

		//* If the firm doesn't use the paralegals userType, then no need to make the request
		if (this.company.info.isLawFirm) {
			//! Will make a request to API every time they go to create a new case
			//! Can't do the usual peekAll and fallback to findAll when empty since some caseType come with the cases request...
			const paralegals = await this.store.findAll('paralegal');
			this.set('paralegals', paralegals.toArray());
		}

		//! Will make a request to API every time they go to create a new case
		//! Can't do the usual peekAll and fallback to findAll when empty since some caseType come with the cases request...
		const caseTypes = await this.store.findAll('caseType');
		this.set('caseTypes', caseTypes.toArray());

		//* Incident date is not required so only set it if present
		if (this.model.get('date') != null) {
			const formattedDate = moment(this.model.get('date'), 'YYYY-MM-DD').format(
				'MM/DD/YYYY',
			);
			this.set('date', formattedDate);
		}

		this.set('attorney', this.model.get('attorney'));

		//* set correct error message for US/EU firms
		const firm = this.currentUser.get('user.firm');
		this.set(
			'noAttorneysError',
			`Must assign a minimum of 1 ${attorneyDisplay([firm])}`,
		);

		//* Setup observers
		const _this = this;
		this.addObserver(_this, 'date', 'dateObserver');
		this.addObserver(_this, 'model.attorney.length', 'attorneyObserver');
		this.addObserver(_this, 'model.caseType', 'caseTypeObserver');
		this.addObserver(_this, 'model.caseType', 'sortedCaseStatusesObserver');
		this.addObserver(_this, 'potentialStatuses', 'sortedCaseStatusesObserver');
		this.addObserver(
			_this,
			'sortedCaseStatuses.@each',
			'sortedCaseStatusesObserver',
		);

		//* Check if portal customer and get dynamic settings if so
		this.permissions.fetchDynamicFirmSettings({
			setting: 'case_activation_quota',
		});
	}

	nameMatcher(option, searchTerm) {
		const search = searchTerm.toLowerCase();
		const name = option.get('name').toLowerCase();
		return name.indexOf(search);
	}

	@action
	selectedAttorney(attorneys) {
		this.set('model.attorneys', A(attorneys));
		this.attorneyObserver();
	}

	@action
	selectedParalegal(paralegals) {
		this.set('model.paralegals', paralegals);
	}

	@action
	selectedCaseStatus(status) {
		this.set('model.caseStatus', status);
	}

	@action
	selectedCaseType(caseType) {
		this.set('model.caseType', caseType);
	}

	@action
	saveButtonPressed() {
		this.set('showError', true);

		//* Call the date formatter if there is a date value
		if (this.date) {
			this.dateObserver();
		}

		const isValid = this.model.get('validations.isValid');

		const result = defer();

		if (isValid && !this.showDateError) {
			this.nextStep(this.model, result);
			result.promise.catch((errors) => {
				this.set('errors', errors);
			});
		} else {
			result.reject();
		}

		return result.promise;
	}

	@action
	saveButtonPressedAndCreateAnother() {
		this.set('showError', true);
		const isValid = this.model.get('validations.isValid');

		const result = defer();

		if (isValid && !this.showDateError) {
			this.nextStep(this.model, result, true);

			result.promise.catch((errors) => {
				this.set('errors', errors);
			});
		} else {
			result.reject();
		}

		return result.promise.then(() => {
			this.set('model', this.store.createRecord('case'));
			this.previousStep();
		});
	}

	@action
	transitionToCaseTypes() {
		this.router.transitionTo('app.firm.settings.case-types');
	}
}
