/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import moment from 'moment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reject } from 'rsvp';

import { MESSAGING_OFF_APPOINTMENTS_ERROR } from '../constants/errors';

@classic
export default class AppointmentForm extends Component {
	tagName = '';

	@service currentUser;
	@service store;
	@service permissions;
	@service ajax;

	@tracked hourDate = null;
	@tracked minuteDate = null;
	@tracked monthDate = null;
	@tracked amOrPm = null;
	@tracked deleteState = false;
	@tracked dateChanged = false;
	@tracked changeset;
	@tracked errors;
	timezone = this.currentUser.get('user.firm.timezone') || 'America/New_York';
	init(...args) {
		super.init(...args);
		this.localizeDateTime = moment.tz(
			this.treatment.get('appointmentDate'),
			this.timezone,
		);
		this.changeset = new Changeset(this.treatment);
		this.changeset.on('afterRollback', () => {
			if (this.onAfterRollback) {
				this.onAfterRollback();
			}
		});

		if (this.isNewTreatment) {
			let currentMoment = moment();
			let minute = currentMoment.minute();

			if (minute < 30) {
				this.set('minuteDate', '30');
			} else {
				currentMoment = currentMoment.add(1, 'hour').startOf('hour');
				this.set('minuteDate', '00');
			}
			this.set('hourDate', currentMoment.format('hh'));
			this.set('amOrPm', currentMoment.format('a'));
			this.set('monthDate', currentMoment.format('MM/DD/YYYY'));

			this.changeset.set(
				'confirmationEnabled',
				this.appointmentConfirmationDefault,
			);
			this.changeset.set(
				'confirmationDaysBefore',
				this.permissions?.case?.appointment_confirmation_days_before,
			);
			if (this.appointmentInternalConfirmationEnabled) {
				this.changeset.set(
					'internalNotificationDaysBefore',
					this.permissions?.case?.appointment_internal_notification_days_before,
				);
			}
		} else {
			this.set('hourDate', this.localizeDateTime.format('hh'));
			this.set('minuteDate', this.localizeDateTime.format('mm'));
			this.set('amOrPm', this.localizeDateTime.format('a'));
			this.set('monthDate', this.localizeDateTime.format('MM/DD/YYYY'));
		}
	}

	hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	minuteOptions = Array.from(Array(60).keys()).map((x) => {
		let str = x.toString(10);
		if (str < 10) {
			str = '0' + str;
		}
		return str;
	});
	amOrPmOptions = ['AM', 'PM'];

	get isNewTreatment() {
		return !this.treatment.get('createdAt');
	}

	isTreatment = false;

	get internalNotificationEnabled() {
		const daysBefore = this.changeset.get('internalNotificationDaysBefore');
		return (
			this.changeset.get('confirmationEnabled') &&
			!!daysBefore &&
			Number(daysBefore) > 0
		);
	}

	get validations() {
		let isValid = true;
		let errors = null;

		if (!this.changeset.get('appointmentTitle')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.appointmentTitle = { message: 'You must include a title' };
		}

		if (!this.changeset.get('description')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.description = { message: 'You must include a description' };
		}

		if (this.currentUser.user.firm.messagingOff) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.description = {
				message: MESSAGING_OFF_APPOINTMENTS_ERROR,
			};
		}
		const today = moment(new Date());
		const appointmentDate = this.changeset.get('appointmentDate');
		if (today > appointmentDate) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.server = {
				message: 'Appointments can not be created in the past',
			};
		}

		if (this.changeset.get('confirmationEnabled')) {
			const confirmDaysBefore = this.changeset.get('confirmationDaysBefore');
			const staffDaysBefore = this.changeset.get(
				'internalNotificationDaysBefore',
			);
			if (
				this.changeset.get('internalNotificationEnabled') &&
				confirmDaysBefore &&
				staffDaysBefore &&
				staffDaysBefore > confirmDaysBefore
			) {
				isValid = false;
				if (!errors) {
					errors = {};
				}
				errors.confRequest = {
					message:
						'Staff cannot be alerted before the Client Confirmation Request is sent. Please change the Staff Alert to be less days before the Client Confirmation Request.',
				};
			}

			if (today.add(confirmDaysBefore, 'd') > appointmentDate) {
				isValid = false;
				if (!errors) {
					errors = {};
				}
				errors.confRequest = {
					message:
						'The confirmation date must be set to tomorrow at the soonest.',
				};
			}

			if (confirmDaysBefore < 1) {
				isValid = false;
				if (!errors) {
					errors = {};
				}
				errors.confRequest = {
					message:
						'The confirmation request must be at least one day before the scheduled appointment.',
				};
			}
		}

		return {
			isValid,
			errors,
		};
	}

	get showCalendarToggle() {
		if (
			!this.changeset.get('id') &&
			((this.currentUser.user.googleCalendarId &&
				this.currentUser.user.isGoogleOauthSet) ||
				(this.currentUser.user.wantsIcsInvites &&
					this.currentUser.user.firm?.get(
						'firmSettings.firmSettings.ics_invites',
					)))
		) {
			return true;
		} else if (
			!this.changeset.get('id') &&
			!this.currentUser.user.googleCalendarId &&
			!this.currentUser.user.isGoogleOauthSet
		) {
			let showCalendarToggle = false;
			const caseAttorneys = this.changeset.get('case.attorneys').toArray();
			const caseParalegals = this.changeset.get('case.paralegals').toArray();
			const caseWorkers = [...caseAttorneys, ...caseParalegals];

			for (let i = 0; i < caseWorkers.length; i++) {
				const caseWorker = caseWorkers[i];
				if (caseWorker.googleCalendarId && caseWorker.isGoogleOauthSet) {
					showCalendarToggle = true;
					break;
				}
			}

			return showCalendarToggle;
		} else {
			return false;
		}
	}

	get showCalendarReauthMessage() {
		const isNew = !this.changeset.get('id');
		const isGcalUnauthed =
			this.currentUser.user.googleCalendarId &&
			!this.currentUser.user.isGoogleOauthSet;
		const isIcsUnauthed =
			(this.currentUser.user.wantsIcsInvites &&
				!this.currentUser.user.firm?.get(
					'firmSettings.firmSettings.ics_invites',
				)) ||
			(!this.currentUser.user.wantsIcsInvites &&
				this.currentUser.user.firm?.get(
					'firmSettings.firmSettings.ics_invites',
				));

		//*gcal takes precedence
		if (
			isNew &&
			!this.currentUser.user.googleCalendarId &&
			!!this.currentUser.user.isGoogleOauthSet &&
			(isGcalUnauthed || isIcsUnauthed)
		) {
			return true;
		} else {
			return false;
		}
	}

	get reauthMessageType() {
		//* If showCalendarReauthMessage is false, return null.
		//* If user wants ICS (outlook) but firm is not ICS enabled, display that message
		//* If user doesn't want ICS but wants Google Calendar, display that message
		//* If user doesn't have ICS or google calendar setup, but firm is ICS enabled, display that message.
		//* Return null if no condition is met

		if (!this.showCalendarReauthMessage) return null;

		const user = this.currentUser?.get('user');
		const firm = user?.get('firm');

		if (
			user.wantsIcsInvites &&
			!firm?.get('firmSettings.firmSettings.ics_invites')
		) {
			return 'ics1';
		} else if (!user.wantsIcsInvites && user.googleCalendarId) {
			return 'gcal1';
		} else if (
			!user.wantsIcsInvites &&
			!user.googleCalendarId &&
			firm?.get('firmSettings.firmSettings.ics_invites')
		) {
			return 'ics2';
		} else {
			return null;
		}
	}

	get showCalendarEditHelperText() {
		const isNew = !this.changeset?.get('id');
		const user = this.currentUser?.get('user');

		if (!isNew && (user.wantsIcsInvites || user.googleCalendarId)) {
			return true;
		} else {
			return false;
		}
	}

	get appointmentConfirmationEnabled() {
		return !!this.permissions?.case?.enable_appointment_confirmation;
	}

	get appointmentConfirmationDefault() {
		const confirmationTypes = (
			this.permissions?.case?.appointment_confirmation_enabled_types || []
		).map((type) => type.toLowerCase());
		return confirmationTypes.includes('meeting');
	}

	get appointmentInternalConfirmationEnabled() {
		const internalConfTypes = (
			this.permissions?.case?.appointment_internal_notification_enabled_types ||
			[]
		).map((type) => type.toLowerCase());
		return internalConfTypes.includes('meeting');
	}

	@action
	onDateChangeHandler(date) {
		this.set('monthDate', moment(date).format('MM/DD/YYYY'));
		this.set('dateChanged', true);
	}

	@action
	onHourChangeHandler(hour) {
		let hourString = hour.toString();
		if (hour < 10) {
			hourString = '0' + hourString;
		}
		this.set('hourDate', hourString);
		this.set('dateChanged', true);
	}

	@action
	onMinuteChangeHandler(minute) {
		this.set('minuteDate', minute.toString());
		this.set('dateChanged', true);
	}

	@action
	onAmpmChangeHandler(amOrPm) {
		this.set('amOrPm', amOrPm);
		this.set('dateChanged', true);
	}

	isSaving = false;
	didSave = false;
	@action
	submitAction(changeset) {
		if (this.isSaving || this.didSave) return reject();
		this.set('isSaving', true);
		const timeString = `${this.monthDate} ${this.hourDate}:${
			this.minuteDate
		} ${this.amOrPm.toUpperCase()}`;
		var apptDateTime = moment
			.tz(timeString, 'MM/DD/YYYY hh:mm a', this.timezone)
			.utc()
			.toDate();

		this.set('changeset.appointmentDate', apptDateTime);
		this.set('changeset.type', 'meeting');
		if (!changeset.get('confirmationEnabled')) {
			this.set('changeset.confirmationDaysBefore', null);
			this.set('changeset.internalNotificationDaysBefore', null);
		}
		this.set(
			'changeset.internalNotificationEnabled',
			this.internalNotificationEnabled,
		);

		if (this.validations.isValid) {
			return changeset
				.save()
				.then((changeset) => {
					this.handleSuccessfulSave(changeset);
				})
				.catch((error) => {
					if (error.isForbidden) {
						console.error('Forbidden Error:', error.message);
						this.handleSuccessfulSave(changeset); // Treat as successful save
					} else {
						// Set the general error message for other types of errors
						this.set('isSaving', false);
						this.set('errors', {
							server: {
								message: 'There was an error saving this in our system.',
							},
						});
					}
				});
		} else {
			this.set('errors', this.validations.errors);
			this.set('isSaving', false);
		}
	}

	handleSuccessfulSave(changeset) {
		if (this.onAfterSave) {
			this.onAfterSave(changeset);
		}
		this.set('isSaving', false);
		this.set('didSave', true);
		this.updateShowNewForm(false); // Ensure form is closed
	}

	@action
	rollback(changeset) {
		let currentMoment = moment();
		let hour = this.localizeDateTime.format('hh');
		let amPm = this.localizeDateTime.format('a');
		let minute;

		if (currentMoment.isBefore(this.localizeDateTime, 'minute')) {
			minute = currentMoment.minute() < 30 ? '30' : '00';
			// If the minute is '00', add one hour to the current moment and update hour and AM/PM
			if (minute === '00') {
				currentMoment = currentMoment.add(1, 'hour');
				hour = currentMoment.format('hh');
				amPm = currentMoment.format('a');
			}
		} else {
			minute = this.localizeDateTime.format('mm');
		}

		// Set the hour, minute, month date, and AM/PM on the component
		this.set('hourDate', hour);
		this.set('minuteDate', minute);
		this.set('monthDate', this.localizeDateTime.format('MM/DD/YYYY'));
		this.set('amOrPm', amPm);
		return changeset.rollback();
	}

	@action
	delete() {
		this.treatment.deleteRecord();
		this.ajax.set('noCache', true);

		return this.treatment.save().then(() => {
			if (this.onAfterSave) {
				this.onAfterSave();
			}
			if (this.onAfterDelete) {
				this.onAfterDelete();
			}
		});
	}

	@action
	toggleWantsCalendarEvents() {
		this.toggleProperty('changeset.wantsCalendarEvents');
	}

	@action
	toggleAppointmentConf() {
		this.changeset.set(
			'confirmationEnabled',
			!this.changeset.get('confirmationEnabled'),
		);
	}

	@action
	updateConfirmationDaysBefore(value) {
		this.changeset.set('confirmationDaysBefore', value);
	}

	@action
	updateInternalNotificationDaysBefore(value) {
		this.changeset.set('internalNotificationDaysBefore', value);
	}
}
