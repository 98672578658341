import Service from '@ember/service';
import IntercomCustomerSupportServiceImplementation from 'case-status/modules/saas-customer-support-implementation/intercom';

/**
 * Service for handling SaaS customer support functionalities. This service, is an example of the strategy pattern, passing the control
 * flow to the underlying implementation. This implementation will eventually be replaced by the new implementation changing the behavior
 * of the service without changing the client code.
 */
export default class SaasCustomerSupportService extends Service {
	/**
	 * Determines and returns the appropriate customer support service implementation.
	 * @returns {AbstractCustomerSupportService} The customer support service implementation.
	 */
	get impl() {
		return new IntercomCustomerSupportServiceImplementation();
	}

	/**
	 * Sets the user for the primary widget.
	 * @param {...any} args - Arguments to be passed to the implementation.
	 * @returns {void}
	 */
	setPrimaryWidgetUser(...args) {
		return this.impl.setPrimaryWidgetUser(...args);
	}

	/**
	 * Renders the primary widget.
	 * @returns {string} - the script to append
	 */
	renderPrimaryWidget() {
		return this.impl.renderPrimaryWidget();
	}

	/**
	 * Gets the customer support email.
	 * @returns {string} The customer support email.
	 */
	customerSupportEmail() {
		return this.impl.customerSupportEmail();
	}

	/**
	 * Gets the customer support link.
	 * @returns {string} The customer support link.
	 */
	customerSupportLink() {
		return this.impl.customerSupportLink();
	}
}
