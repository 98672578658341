/** @format */

import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Errors from '../../../constants/errors';
import ENV from 'case-status/config/environment';
import FileHandler from '../../../modules/FileHandler';
import { reviewDisplay } from '../../../helpers/review-display';
import moment from 'moment';

export default Route.extend({
	currentUser: service(),
	id: null,
	session: service(),
	store: service(),
	ajax: service(),
	permissions: service(),
	thirdParty: service(),
	async model(params) {
		this.set('id', params.case_id);
		const theCase = await this.store.findRecord('case', params.case_id, {
			reload: true,
		});

		const chats = this.store.query('chat', {
			caseId: params.case_id,
			filter: { external: 'true' },
		});
		const internal = this.store.query('chat', {
			caseId: params.case_id,
			filter: { internal: 'true' },
		});
		const notifications = this.store.query('notification', {
			filter: { case_id: params.case_id },
			page: { size: 50 },
		});
		const messageTemplates = this.store.findAll('message-template');

		return hash({
			theCase: theCase,
			chats,
			internal,
			notifications,
			messageTemplates,
		});
	},

	async setupController(controller, model) {
		this._super(...arguments);
		if (!model.theCase) {
			model = await this.model(model);
		}
		controller.set('isOnHold', model.theCase.get('onHold'));
		controller.set('isClosed', model.theCase.get('closed'));
		controller.set('wantsSms', model.theCase.get('wantsSms'));
		controller.set('group', model.theCase.get('group'));
		controller.set('oldCaseStatus', null);
		controller.set('caseSyncRequested', false);
		controller.set('caseSyncSucceeded', false);

		//* Setup the permissions service with the caseSettings
		this.set('permissions.case', model.theCase.caseSettings);

		if (
			controller.setCaseTimers &&
			typeof controller.setCaseTimers === 'function'
		) {
			// This is only to fix tests
			controller.setCaseTimers();
		}
	},

	resetController(controller, isExiting) {
		if (isExiting) {
			controller.set('downloadTextSuccess', false);
			controller.set('downloadTextErrors', false);
			controller.set('showMessageFilter', false);

			//* Remove the case from permissions service
			this.set('permissions.case', null);

			//* Unload case specific data
			this.store.unloadAll('medicalProvider');
		}
		controller.cancelCaseTimers();
	},

	actions: {
		deleteMessage(message, result) {
			message.destroyRecord().then(() => result.resolve());
		},

		editMessage(message, content, selectedTime, result) {
			let url = ENV.host + '/messages/' + message.get('id');
			// currently the scheduled endpoint does not specify case id, so we must set it.
			const data = {
				data: {
					type: 'messages',
					attributes: {
						content: content,
						scheduled_message_send_date: selectedTime,
					},
					relationships: {
						case: {
							data: {
								type: 'cases',
								id: 13,
							},
						},
					},
				},
			};
			return this.ajax
				.patch(url, {
					data: JSON.stringify(data),
				})
				.then((resp) => {
					result.resolve(resp);
				});
		},

		sendDownloadLinkTextToNotOpenedClients(caseId, result) {
			let url = ENV.host + '/cases/send_down_link/' + caseId;
			const controller = this.controller;

			controller.set('downloadTextSuccess', false);
			controller.set('downloadTextErrors', null);
			return this.ajax
				.request(url, {
					contentType: 'application/vnd.api+json',
					method: 'POST',
				})
				.then(() => {
					controller.set('showClientActionsModal', false);
					controller.set('downloadTextSuccess', true);
					result.resolve();
				})
				.catch((errors) => {
					controller.set(
						'downloadTextErrors',
						Errors.mapResponseErrors(errors),
					);
					result.reject(errors);
				});
		},
		sendDownloadLinkTextToClient(clientId, caseId) {
			let url = `${ENV.host}/clients/send_down_link/${caseId}/${clientId}`;
			const controller = this.controller;

			controller.set('downloadTextSuccess', false);
			controller.set('downloadTextErrors', null);

			return this.ajax
				.post(url)
				.then(() => {
					controller.set('downloadTextSuccess', true);

					const client = this.store.peekRecord('client', clientId);

					if (client) {
						client.set('invite', true);
					} else {
						throw 'Client not found';
					}
				})
				.catch((errors) => {
					controller.set(
						'downloadTextErrors',
						Errors.mapResponseErrors(errors),
					);
				});
		},

		requestReview() {
			this.controller.set('showMessageActionsModal', false);
			if (this.preventReviewRequest) {
				this.controller
					.get('notifications')
					.warning(
						`You must wait at least 10 seconds before requesting another review.`,
						{
							autoClear: true,
							clearDuration: 7000,
							// cssClasses: 'notification--success'
						},
					);
				return false;
			}
			this.set('preventReviewRequest', true);
			const reviewLink = this.get('currentUser.user.firm.reviewLink');
			if (reviewLink) {
				const caseId = this.get('currentModel.theCase.id');
				let url = ENV.host + '/cases/' + caseId + '/request_review';

				return this.ajax
					.post(url)
					.then((msg) => {
						this.store.pushPayload('message', msg);
						this.controller
							.get('notifications')
							.success(
								`We sent a message to the client requesting a ${reviewDisplay([
									reviewLink,
								])} review!`,
								{
									autoClear: true,
									clearDuration: 7000,
									cssClasses: 'notification--success',
								},
							);
						setTimeout(() => {
							this.set('preventReviewRequest', false);
						}, 10000);
					})
					.catch((errors) => {
						this.set('preventReviewRequest', false);
						Errors.mapResponseErrors(errors).forEach((err) => {
							this.controller.get('notifications').error(err, {
								autoClear: true,
								clearDuration: 7000,
							});
						});
					});
			} else {
				this.transitionTo('app.firm.settings.integrations');
			}
		},

		exportMessages(result) {
			const caseModel = this.modelFor('app.firm.case').theCase;

			const url = ENV.host + `/messages/download?caseId=${caseModel.get('id')}`;

			return this.ajax
				.request(url)
				.then(({ data }) => {
					FileHandler.download(data.attributes.url, data.attributes.file_name);
					result.resolve();
				})
				.catch((error) => result.reject(error));
		},

		postChatMessage(chatId, content, result) {
			const theCase = this.modelFor('app.firm.case').theCase;
			const chat = theCase.chats.filter((chat) => {
				return chat.id == chatId;
			})[0];

			//* Add 10 seconds to try and account for variance in message time and system time
			const createdAt = moment().add(10, 'seconds').toDate();

			const newChatMessage = this.store.createRecord('chat-message', {
				content,
				chat,
				createdAt,
				senderName: this.get('currentUser.user.name'),
				sender: this.get('currentUser.user'),
			});

			newChatMessage
				.save()
				.then(() => {
					result.resolve(newChatMessage);
				})
				.catch((res) => {
					newChatMessage.rollbackAttributes();
					const controller = this.controllerFor('app.firm.case');
					controller.set('errors', Errors.mapResponseErrors(res));
					result.reject();
				});
		},

		willTransition() {
			const infoController = this.controllerFor('app.firm.case.info');
			infoController.send('cancelEditing');
			return true;
		},
	},
});
